export const ADD_TO_HOME_SCREEN = "Add to Home Screen";
export const CHANGE_THEME = "Change Theme";
export const BUY_DEVELOPER_COFFEE = "Buy Developer Coffee";
export const MORE_APPS = "More Apps";
export const PRIVACY_POLICY = "Privacy Policy";
export const TERMS_OF_USE = "Terms of Use";
export const TOOLTIP_CONSIDER_ADDING_APP = "Consider adding app to homescreen to use offline.";
export const TOOLTIP_SCROLL_DOWN_TO = "Scroll down to view keyboard shortcuts.";
export const TOOLTIP_WORKS_GREAT_ON = "Works great on mobile devices!";
export const LANDING_PAGE_DESCRIPTION = `Welcome to Hour Adder!  This simple web app helps you add measurements of time.  Make sure to add to your home screen to use offline!`;

import React, {Component} from 'react';
import './LandingPage.css';
import Calculator from '../Calculator/Calculator';
import {Navbar, Button, Overlay, Tooltip} from 'react-bootstrap';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Route } from "react-router-dom";
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy';
import TermsOfUse from '../TermsOfUse/TermsOfUse';
import {TOOLTIP_CONSIDER_ADDING_APP, TOOLTIP_SCROLL_DOWN_TO, TOOLTIP_WORKS_GREAT_ON, LANDING_PAGE_DESCRIPTION} from '../../strings-en';
import _ from 'lodash';

class LandingPage extends Component {

    constructor(props){
        super(props);
        this.onSkipLandingPageCheckboxClicked = this.onSkipLandingPageCheckboxClicked.bind(this);
        this.onLaunchAppButtonClick = this.onLaunchAppButtonClick.bind(this);
        this.renderLandingPage = this.renderLandingPage.bind(this);
        this.renderFooterText = this.renderFooterText.bind(this);
        this.renderAdvertisement = this.renderAdvertisement.bind(this);
        this.onSocialMediaButtonClick = this.onSocialMediaButtonClick.bind(this);
        this.getSkipLandingPage = this.getSkipLandingPage.bind(this);
        this.getShowOverlay = this.getShowOverlay.bind(this);
        this.getDescriptionText = this.getDescriptionText.bind(this);

        this.calculatorRef = target => this.setState({target});
        this.state = {
            skipLandingPageCheckboxChecked: false,
            skippedLandingPage: 'not yet',
            mobileAppRunning: false,
            showConsidering: 'true'
        }
    }

    componentDidMount(){
        this.getSkipLandingPage();
    }

    onSkipLandingPageCheckboxClicked(){
        this.setState({skipLandingPageCheckboxChecked: !this.state.skipLandingPageCheckboxChecked});
    }

    getSkipLandingPage(){
        if('caches' in window){
            caches.open('skipLandingPage').then((cache) => {
            const request = new Request('skipLandingPage');
            cache.match(request).then((response) => {
              response.text().then(e => {
                  this.setState({mobileAppRunning: e === 'true', skippedLandingPage: e.toString()});
              }
              )
              .catch(e => this.setState({skippedLandingPage: 'false'}));
            }).catch(e => this.setState({skippedLandingPage: 'false'}));
          }).catch(e => this.setState({skippedLandingPage: 'false'}));
        } else {
            this.setState({skippedLandingPage: 'false'});
        }
    }

    onLaunchAppButtonClick(){
        if('caches' in window){
            caches.open('skipLandingPage').then((cache) => {
            const response = new Response(this.state.skipLandingPageCheckboxChecked);
            cache.put('skipLandingPage', response);
            });
        }
        this.setState({mobileAppRunning: true});
    }

    onSocialMediaButtonClick(button){
        if(button === 'twitter'){
            window.open('https://twitter.com/intent/tweet?text=Add%20up%20time%20with%20this%20great%20web%20app%20I%20found%20https://houradder.com/');
        } else if(button === 'email'){
            window.open("mailto:?subject=HourAdder&body=Add%20up%20time%20with%20this%20great%20web%20app:%20https://houradder.com/");
        }
    }

    getShowOverlay(){
        return null;
    }

    renderAdvertisement(){
        return (<span dangerouslySetInnerHTML={{__html: '<iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=12&l=ur1&category=topgiftideas&banner=11QH56VCWYW6G4PKV382&f=ifr&lc=pf4&linkID=4e2b72ca60171098feac0d9b128a076b&t=hourapps-20&tracking_id=hourapps-20" width="300" height="250" scrolling="no" border="0" marginwidth="0" style="border:none;" frameborder="0"></iframe>'}}/>);
    }

    renderFooterText(){
        return(
            <div className={'LandingPage-mobile-footer-text'} >
                Hour Adder, 2020
                <br/>
                contact@houradder.com
                <br/>
                Build {process.env.REACT_APP_BUILD_VERSION}
                <br/>
                <a className={'link-text'} rel="noopener noreferrer" target="_blank" href="/terms">Terms of service</a> - <a className={'link-text'} rel="noopener noreferrer" target="_blank" href="/privacy">Privacy Policy</a>
                <br/>
                <a className={'link-text'} rel="noopener noreferrer" target="_blank" href="https://www.buymeacoffee.com/smarturano">Buy Developer Coffee!</a>
            </div>
        );
    }

    getDescriptionText(){
        return LANDING_PAGE_DESCRIPTION;
    }

    renderLandingPage(){
        if(this.props.isMobile && this.state.mobileAppRunning && this.state.skippedLandingPage !== 'not yet'){
            return(
                <div>
                    <div className={`LandingPage-calculator-mobile-header`}>
                        Hour Adder
                        <br/>
                        <a className={'link-text'} rel="noopener noreferrer" target="_blank" href="https://www.surveymonkey.com/r/FKJBR6F">Submit feedback!</a>
                    </div>
                    <Calculator history={this.props.history}/>
                    <div className={`LandingPage-calculator-mobile-footer`}>
                        Hour Adder
                        <br/>
                        <a className={'link-text'} rel="noopener noreferrer" target="_blank" href="https://www.surveymonkey.com/r/FKJBR6F">Submit feedback!</a>
                    </div>
                </div>
            )
        }
        if(this.props.isMobile && this.state.skippedLandingPage === 'not yet'){
            return(
                <div className={'LandingPage-mobile'}>
                    <div className={'LandingPage-mobile-body'}>
                        Loading...
                    </div>
                </div>
            );
        }
        return(
            <div>
                {
                    this.props.isMobile ? 
                    <div className={'LandingPage-mobile'}>
                        <div className={'LandingPage-mobile-body'}>
                            <img
                                        alt=""
                                        src="apple-touch-icon.png"
                                        className="LandingPage-mobile-image"
                                    />
                            <div className={'LandingPage-mobile-title'}>
                                Hour Adder
                            </div>
                            <div className={'LandingPage-mobile-description'}>
                                { this.getDescriptionText() }
                            </div>
                            <Button variant="danger" onClick={this.onLaunchAppButtonClick}>Launch App!</Button>
                            
                            <FormGroup row>
                                <FormControlLabel
                                className={'LandingPage-mobile-description'}
                                control={
                                    <Checkbox
                                    checked={this.state.skipLandingPageCheckboxChecked}
                                    onChange={this.onSkipLandingPageCheckboxClicked}
                                    />
                                }
                                label="Skip this page and go directly to app next time I visit"
                                />
                            </FormGroup>
                        </div>
                        {this.renderAdvertisement()}
                        <div className={'LandingPage-mobile-footer'}>
                            {this.renderFooterText()}
                        </div>
                    </div> 
                    : 
                    <div className={'LandingPage-desktop'}>
                        <div className={'LandingPage-desktop-header'}>
                            <Navbar className={'LandingPage-desktop-navbar'} expand="lg" variant="light" bg="light">
                                    <Navbar.Brand>
                                    <img
                                        alt=""
                                        src="apple-touch-icon.png"
                                        className="LandingPage-desktop-image"
                                    />
                                    <div className="LandingPage-desktop-title">
                                        Hour Adder    
                                    </div>
                                    </Navbar.Brand>
                                    <Navbar.Brand className={'LandingPage-desktop-social-media-buttons'}>
                                        <div className={'facebook-social-media-button'}>
                                            <div className="fb-share-button" data-href="https://houradder.com" data-layout="button_count" data-size="small"><a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fhouradder.com%2F&amp;src=sdkpreparse" className="fb-xfbml-parse-ignore">Share</a></div>
                                        </div>
                                        <div onClick={e => this.onSocialMediaButtonClick('twitter')} className={'LandingPage-desktop-social-media-button fa fa-twitter'}></div>
                                        <div onClick={e => this.onSocialMediaButtonClick('email')} className={'LandingPage-desktop-social-media-button fa fa-envelope'}></div>
                                    </Navbar.Brand>
                            </Navbar>
                        </div>
                        <div className={'LandingPage-desktop-body'}>
                            <Calculator ref={this.calculatorRef} width={'300'} height={'525'}/>
                            
                            <Overlay target={this.state.target} show={true} placement="right" container={this}>
                            {props => (
                                <Tooltip id="overlay-example" {..._.omit(props, ['show'])}  className={"tooptip-bottom"} onClick={e => {e.currentTarget.hidden = true}}>
                                    {TOOLTIP_CONSIDER_ADDING_APP}
                                </Tooltip>
                            )}
                            </Overlay>
                            <Overlay target={this.state.target} show={true} placement="left">
                            {props => (
                                <Tooltip id="overlay-example" {..._.omit(props, ['show'])} onClick={e => {e.currentTarget.hidden = true}}>
                                    {TOOLTIP_SCROLL_DOWN_TO}
                                </Tooltip>
                            )}
                            </Overlay>
                            <Overlay target={this.state.target} show={true} placement="right" >
                            {props => (
                                <Tooltip id="overlay-example" {..._.omit(props, ['show'])} className={"tooptip-top"} onClick={e => {e.currentTarget.hidden = true}}>
                                    {TOOLTIP_WORKS_GREAT_ON}
                                </Tooltip>
                            )}
                            </Overlay>
                            <div className={'LandingPage-desktop-description'}>
                                { this.getDescriptionText() }
                            </div>
                            <div className={'LandingPage-desktop-body-shortcuts'}>
                                <div className={'LandingPage-desktop-body-shortcuts-keyboard'}>
                                    <div>Keyboard</div>
                                    <img width="500px" src="keyboard.png" alt="Keyboard shortcuts"/>
                                </div>
                                <br/>
                                <div className={'LandingPage-desktop-body-shortcuts-numpad'}>
                                    <div className={'LandingPage-desktop-body-shortcuts-numpad-title'}>Numpad</div>
                                    <img width="383px" src="numpad.png" alt="Numpad shortcuts"/>
                                </div>
                            </div>
                        </div>
                        <div className={'LandingPage-desktop-footer'}>
                            {this.renderAdvertisement()}
                            {this.renderFooterText()}
                        </div>
                    </div>
                }
            </div>
        );
    }

    render(){
        return(
            <div>
                <Route exact path="/" render={this.renderLandingPage}/>
                <Route path="/privacy/" component={PrivacyPolicy}/>
                <Route path="/terms/" component={TermsOfUse}/>
            </div>
        );
    }
}

export default LandingPage;
import React, { Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Route } from "react-router-dom";
import LandingPage from './components/LandingPage/LandingPage'
import { isMobile } from 'react-device-detect';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <div className="App">
        <Router>
          <Route path="/" render={(props) => <LandingPage {...props} isMobile={isMobile}/>}/>
        </Router>
      </div>
    );
  }
}

export default App;

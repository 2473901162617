import React, { Component } from 'react';
import './Calculator.css';
import Keyboard from '../Keyboard/Keyboard';
import {ADD_TO_HOME_SCREEN, CHANGE_THEME, BUY_DEVELOPER_COFFEE, MORE_APPS, PRIVACY_POLICY, TERMS_OF_USE} from '../../strings-en';
import {Modal, ListGroup, Button} from 'react-bootstrap';
import themes from '../../themes.json';

class Calculator extends Component {
  constructor(props) {
    super(props);

    this.onAddToHomeScreenClicked = this.onAddToHomeScreenClicked.bind(this);
    this.onKeyboardButtonPress = this.onKeyboardButtonPress.bind(this);
    this.onNextButtonPress = this.onNextButtonPress.bind(this);
    this.onPreviousButtonPress = this.onPreviousButtonPress.bind(this);
    this.onClearButtonPress = this.onClearButtonPress.bind(this);
    this.onNumberButtonPress = this.onNumberButtonPress.bind(this);
    this.addNewTime = this.addNewTime.bind(this);
    this.renderDisplay = this.renderDisplay.bind(this);
    this.renderModals = this.renderModals.bind(this);
    this.focusInitialElement = this.focusInitialElement.bind(this);
    this.focusFinalElement = this.focusFinalElement.bind(this);
    this.focusOnMostRecentTime = this.focusOnMostRecentTime.bind(this);
    this.isFocusedOnTime = this.isFocusedOnTime.bind(this);
    this.getHighestTimeId = this.getHighestTimeId.bind(this);
    this.onBackspaceButtonPress = this.onBackspaceButtonPress.bind(this);
    this.addOrRemoveLeadingZero = this.addOrRemoveLeadingZero.bind(this);
    this.calculateResult = this.calculateResult.bind(this);
    this.onTimeSelect = this.onTimeSelect.bind(this);
    this.onKeyboardButtonHold = this.onKeyboardButtonHold.bind(this);
    this.onListGroupItemButtonPress = this.onListGroupItemButtonPress.bind(this);
    this.hideThemeModal = this.hideThemeModal.bind(this);
    this.renderThemeListGroup = this.renderThemeListGroup.bind(this);
    this.onListGroupItemClicked = this.onListGroupItemClicked.bind(this);
    this.applyTheme = this.applyTheme.bind(this);
    this.onApplyThemeButtonClicked = this.onApplyThemeButtonClicked.bind(this);
    this.setThemeFromCache = this.setThemeFromCache.bind(this);
    this.setCalculatorDimentions = this.setCalculatorDimentions.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.userNotFocusedOnTimeAndPressedKeyboardButton = this.userNotFocusedOnTimeAndPressedKeyboardButton.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this);
    this.disableScrolling = this.disableScrolling.bind(this);
    this.isUsingMobileVersion = this.isUsingMobileVersion.bind(this);
    this.setCalculatorDimentions();
    this.setThemeFromCache();

    window.addEventListener('beforeinstallprompt', (e) => {
      this.setState({installPromptEvent: e});
      e.preventDefault();
    });

    this.state = {
      times: [
        {
          hour: '0',
          minute: '00'
        }
      ],
      focusedElementType: 'hour'
    };
  }

  componentDidUpdate(prevProps, prevState){
    if(this.state.times.length > prevState.times.length){
      this.onNextButtonPress();
    }
    if(prevState.times.length > 1 && this.state.times.length === 1){
      if(this.state.times[0]['hour'] === '0' && this.state.times[0]['minute'] === '00'){
        this.focusInitialElement();
      }
    }
    if(!this.isFocusedOnTime()){
      if(this.state.times.length === 1){
        this.focusInitialElement();
      }
    }
    if(!this.isUsingMobileVersion()){
      //if user is on desktop
      document.addEventListener('keydown', this.onKeyDown)
    }
  }

  componentDidMount(){
    this.disableScrolling();
    this.focusInitialElement();
  }

  disableScrolling(){
    document.addEventListener("touchmove", e => {
      if(e.target.className !== "Calculator-display-result"
        && e.target.className !== "Calculator-display-time-hour"
        && e.target.className !== "Calculator-display-time-minute"){
        e.preventDefault()
      }
    }, {passive: false});
  }

  userNotFocusedOnTimeAndPressedKeyboardButton(){
    if(this.state.times[0]['hour'] === '0' && this.state.times[0]['minute'] === '00' && this.state.times.length === 1){
      this.focusInitialElement();
    } else {
      //TODO show message to user
    }
  }

  onKeyDown(e){
    //Uses physical keyboard
    if(e.key === "0"
      || e.key === "1"
      || e.key === "2"
      || e.key === "3"
      || e.key === "4"
      || e.key === "5"
      || e.key === "6"
      || e.key === "7"
      || e.key === "8"
      || e.key === "9"){
        if(this.isFocusedOnTime()){
          this.onNumberButtonPress(e.key);
        } else {
          this.userNotFocusedOnTimeAndPressedKeyboardButton();
        }
    } else if (e.key === "-" || e.key === "Backspace"){
      if(this.isFocusedOnTime()){
        this.onBackspaceButtonPress();
      } else {
        this.userNotFocusedOnTimeAndPressedKeyboardButton();
      }
    } else if (e.key === "+" || e.key === "ArrowLeft" || e.key === "ArrowUp" || e.key === "\\"){
      if(this.isFocusedOnTime()){
        this.onPreviousButtonPress()
      } else {
        this.userNotFocusedOnTimeAndPressedKeyboardButton();
      }
    } else if (e.key === "Enter" || e.key === "ArrowRight" || e.key === "ArrowDown"){
      if(this.isFocusedOnTime()){
        this.onNextButtonPress();
      } else {
        this.userNotFocusedOnTimeAndPressedKeyboardButton();
      }
    } else if (e.key === "Escape"){
      this.onClearButtonPress();
    }
  }

  isUsingMobileVersion(){
    if(this.props.width && this.props.height){
      return false;
    }
    return true;
  }

  setCalculatorDimentions(){
    if(!this.isUsingMobileVersion()){
      //desktop
      window.document.body.style.setProperty(`--calculator-display`, `inline-flex`);
      window.document.body.style.setProperty(`--calculator-width`, `${this.props.width}px`);
      window.document.body.style.setProperty(`--calculator-height`, `${this.props.height}px`);
      let displayHeight = this.props.height * .4;
      window.document.body.style.setProperty(`--calculator-display-height`, `${displayHeight}px`);
      window.document.body.style.setProperty(`--calculator-display-height-landscape`, `${displayHeight}px`);
      window.document.body.style.setProperty(`--calculator-display-width`, `${displayHeight}px`);
      let keyboardHeight = this.props.height * .6;
      window.document.body.style.setProperty(`--calculator-keyboard-height`, `${keyboardHeight}px`);      
      window.document.body.style.setProperty(`--calculator-keyboard-height-landscape`, `${keyboardHeight}px`);
      window.document.body.style.setProperty(`--calculator-keyboard-width`, `${keyboardHeight}px`);
      window.document.body.style.setProperty(`--calculator-display-result-width-landscape`, `${this.props.width}`);
      window.document.body.style.setProperty(`--calculator-flex-direction-landscape`, `column`);
    } else {
      //mobile
      window.document.body.style.setProperty(`--calculator-display`, `flex`);
      window.document.body.style.setProperty(`--calculator-display-result-width`, `50vw`);
      window.document.body.style.setProperty(`--calculator-display-result-width-landscape`, `50vh`);
      window.document.body.style.setProperty(`--calculator-flex-direction-landscape`, `row`);
      window.document.body.style.setProperty(`--calculator-display-height`, `30vh`);
      window.document.body.style.setProperty(`--calculator-display-height-landscape`, `80vh`);
      window.document.body.style.setProperty(`--calculator-keyboard-height`, `60vh`);
      window.document.body.style.setProperty(`--calculator-keyboard-height-landscape`, `80vh`);
      window.document.body.style.setProperty(`--calculator-footer-height`, `10vh`);
      window.document.body.style.setProperty(`--calculator-header-height-landscape`, `20vh`);
    }
  }

  onAddToHomeScreenClicked(){
    if(this.state.installPromptEvent){
      this.state.installPromptEvent.prompt();
    } else {
      alert(`Unable to add to home screen.`);
    }
  }

  getHighestTimeId(){
    return this.state.times[this.state.times.length-1].id;
  }

  addNewTime(){
    this.setState({
      times: [...this.state.times, {hour: '0', minute: '00'}]
    });
  }

  focusInitialElement(){
    document.getElementById('display-form')[0].focus();
    this.onTimeSelect();
  }

  focusFinalElement(){
    var focusableElement = Array.prototype.filter.call(document.body.querySelectorAll('input'),
    function(element){
      return element.offsetWidth > 0 || element.offsetHeight > 0 || element === document.activeElement
    });
    if(focusableElement.length > 1){
      focusableElement[focusableElement.length-1].focus();
    }
  }

  focusOnMostRecentTime(){
    if(this.state.focusedElementId){
      document.getElementById(this.state.focusedElementId).focus();
    }
  }

  isFocusedOnTime(){
    return document.activeElement.id !== "";
  }

  scrollToTop(){
    if(this.isUsingMobileVersion()){
      //if user is on mobile
      window.scrollTo(0, document.body.scrollHeight);
    }
  }

  calculateResult(){
    let reducer = (accumulator, currentValue) => {
      currentValue.hour = currentValue.hour === "" ? "0" : currentValue.hour;
      currentValue.minute = currentValue.minute === "" ? "0" : currentValue.minute;
      accumulator.hour = accumulator.hour === "" ? "0" : accumulator.hour;
      accumulator.minute = accumulator.minute === "" ? "0" : accumulator.minute;
      let minute = parseInt(accumulator.minute) + parseInt(currentValue.minute);
      let carryOver = 0;
      if(minute >= 60){
        carryOver = Math.floor(minute/60);
        minute %= 60; 
      }
      let hour = parseInt(accumulator.hour) + parseInt(currentValue.hour);
      hour += carryOver;
      return {hour, minute};
    }
    let times = [...this.state.times, {hour: '0', minute: '00'}];
    let result = times.reduce(reducer);
    result.hour = result.hour === "" ? "0" : result.hour;
    result.minute = result.minute === "" ? "0" : result.minute;
    return result;
  }

  onKeyboardButtonHold(type, value){
      console.log(`Held ${type}: ${value}`);
      if(type === 'action'){
        if(this.isFocusedOnTime()){
          if(value === 'backspace'){
            this.onBackspaceButtonPress();
          } else if(value === 'next'){
            this.onNextButtonPress();
          } else if(value === 'previous'){
            this.onPreviousButtonPress();
          }
        } else {
          this.focusOnMostRecentTime();
        }
      }
  }

  onNextButtonPress(){
    if(document.activeElement && document.activeElement.form){
      var focusableElement = Array.prototype.filter.call(document.activeElement.form.querySelectorAll('input'),
      function(element){
        return element.offsetWidth > 0 || element.offsetHeight > 0 || element === document.activeElement
      });
      var index = focusableElement.indexOf(document.activeElement);
      let times = this.state.times;
      let elementId = document.activeElement.id;
      let timeIndex = parseInt(elementId.substring(0, elementId.indexOf('-')));
      let timeKey = elementId.substring(elementId.indexOf('-') + 1);
      if(timeKey === 'hour'){
        times[timeIndex][timeKey] = this.addOrRemoveLeadingZero(times[timeIndex][timeKey], 1);
      } else if(timeKey === 'minute'){
        times[timeIndex][timeKey] = this.addOrRemoveLeadingZero(times[timeIndex][timeKey], 2);
      }
      this.setState({times});
      if(index > -1 && index < focusableElement.length - 1){
        focusableElement[index + 1].focus();
      } else if(index >= focusableElement.length - 1){
        this.addNewTime();
      }
    }
    this.onTimeSelect();
  }

  onPreviousButtonPress(){
    if(document.activeElement && document.activeElement.form){
      var focusableElement = Array.prototype.filter.call(document.activeElement.form.querySelectorAll('input'),
      function(element){
        return element.offsetWidth > 0 || element.offsetHeight > 0 || element === document.activeElement
      });
      let times = this.state.times;
      let elementId = document.activeElement.id;
      let timeIndex = parseInt(elementId.substring(0, elementId.indexOf('-')));
      let timeKey = elementId.substring(elementId.indexOf('-') + 1);
      if(timeKey === 'hour'){
        times[timeIndex][timeKey] = this.addOrRemoveLeadingZero(times[timeIndex][timeKey], 1);
      } else if(timeKey === 'minute'){
        times[timeIndex][timeKey] = this.addOrRemoveLeadingZero(times[timeIndex][timeKey], 2);
      }
      this.setState({times});
      var index = focusableElement.indexOf(document.activeElement);
      if(index > 0){
        focusableElement[index - 1].focus();
      }
    }
    this.onTimeSelect();
  }

  onBackspaceButtonPress(){
    if(document.activeElement.value.length > 0){
      let elementId = document.activeElement.id;
      let timeIndex = parseInt(elementId.substring(0, elementId.indexOf('-')));
      let timeKey = elementId.substring(elementId.indexOf('-') + 1);
      let times = this.state.times;
      times[timeIndex][timeKey] = times[timeIndex][timeKey].substring(0, times[timeIndex][timeKey].length - 1);
      this.setState({times});
    } else {
      let elementId = document.activeElement.id;
      let timeIndex = parseInt(elementId.substring(0, elementId.indexOf('-')));
      let timeKey = elementId.substring(elementId.indexOf('-') + 1);
      let times = this.state.times;
      this.onPreviousButtonPress();
      if(timeKey === 'hour' && times[timeIndex]['hour'] === '0' 
        && (times[timeIndex]['minute'] === '00' || times[timeIndex]['minute'] === '0' ) 
        && this.state.times.length > 1){
        if(timeIndex === 0){
          this.onNextButtonPress();
        }
        times.splice(timeIndex, 1);
      }
      this.setState({times});
    }
  }

  addOrRemoveLeadingZero(value, desiredLengthOfValue){
    let canModify = true;
    let result = value;
    while(result.length !== desiredLengthOfValue && canModify){
      if(result.length > desiredLengthOfValue){
        if(result.charAt(0) === '0'){
          result = result.substring(1);
        } else {
          canModify = false;
        }
      } else if (result.length < desiredLengthOfValue){
        result = `0${result}`;
      }
    }
    return result;
  }

  onNumberButtonPress(value){
    let elementId = document.activeElement.id;
    let timeIndex = parseInt(elementId.substring(0, elementId.indexOf('-')));
    let timeKey = elementId.substring(elementId.indexOf('-') + 1);
    let times = this.state.times;
    times[timeIndex][timeKey] += value;
    if(timeKey === 'hour'){
      times[timeIndex][timeKey] = this.addOrRemoveLeadingZero(times[timeIndex][timeKey], 1);
    } else if(timeKey === 'minute'){
      times[timeIndex][timeKey] = this.addOrRemoveLeadingZero(times[timeIndex][timeKey], 2);
    }
    this.setState({times});
  }

  onClearButtonPress(){
      this.setState({times: [{hour: '0',minute: '00'}]})
  }

  onKeyboardButtonPress(type, value){
    console.log(`${type}: ${value}`);
    if(type === 'number'){
      if(this.isFocusedOnTime()){
        this.onNumberButtonPress(value);
      }
    } else if(type === 'action'){
      if(this.isFocusedOnTime()){
        if(value === 'backspace'){
          this.onBackspaceButtonPress();
        } else if(value === 'next'){
          this.onNextButtonPress();
        } else if(value === 'previous'){
          this.onPreviousButtonPress();
        }
      }
      if(value === 'clear'){
        this.onClearButtonPress();
      } else if(value === 'addToHomeScreen'){
        this.onAddToHomeScreenClicked();
      }
    }
  }

  onTimeSelect(){
    let elementId = document.activeElement.id;
    let timeKey = elementId.substring(elementId.indexOf('-') + 1);
    this.setState({focusedElementId: elementId, focusedElementType: timeKey});
  }

  onListGroupItemButtonPress(e){
    let key = e.target.firstChild.data;
    if(key === ADD_TO_HOME_SCREEN){
      this.onAddToHomeScreenClicked();
    } else if (key === CHANGE_THEME){
      document.body.click();
      this.setState({showThemeModal: true});
    } else if (key === BUY_DEVELOPER_COFFEE){
      window.open("https://www.buymeacoffee.com/smarturano");
    } else if (key === MORE_APPS){
      window.open("http://smarturano.com/projects.html");
    } else if (key === PRIVACY_POLICY){
      window.open("./privacy");
    } else if (key === TERMS_OF_USE){
      window.open("./terms");
    }
  }

  renderDisplay(){
    let times = this.state.times.map((e, i) => {
      return(
          <div key={`${i}-div`} className={'Calculator-display-time'}>
              <input type="text" className={'Calculator-display-time-hour'} id={`${i}-hour`}value={e.hour} onClick={this.onTimeSelect} readOnly/>
              <div className={'Calculator-display-time-colon'}>:</div>
              <input type="text" className={'Calculator-display-time-minute'} id={`${i}-minute`} value={e.minute} onClick={this.onTimeSelect} readOnly/>
          </div>
      );
    });
    let result = this.calculateResult();
    return(
        <div className="Calculator-display">
          <div className="Calculator-display-table">
            <form id="display-form" className={'Calculator-display-form'}>
                {times}
            </form>
            <div className={'Calculator-display-result'} onClick={this.focusFinalElement}>
              {`${result.hour}h ${result.minute}m`}
            </div>
          </div>
        </div>
    );
  }

  hideThemeModal(){
    this.setState({showThemeModal: false});
  }

  setThemeFromCache(){
    if('caches' in window){
        caches.open('theme').then((cache) => {
        const request = new Request('theme');
        cache.match(request).then((response) => {
          response.text().then(e => this.applyTheme(e))
          .catch(e => this.applyTheme("Sky Blue"));
        }).catch(e => this.applyTheme("Sky Blue"));
      }).catch(e => this.applyTheme("Sky Blue"));
    } else {
      this.applyTheme("Sky Blue");
    }
  }

  applyTheme(themeName){
    let theme = themes[themeName];
    for(var key in theme){
      window.document.body.style.setProperty(`--${key}`, `${theme[key]}`);
    }
    if('caches' in window){
      caches.open('theme').then((cache) => {
        const response = new Response(themeName);
        cache.put('theme', response);
        this.setState({selectedThemeInList: themeName});
      });
    }
  }

  onApplyThemeButtonClicked(){
    this.applyTheme(this.state.selectedThemeInList);
    this.hideThemeModal();
  }

  onListGroupItemClicked(e) {
    this.setState({selectedThemeInList: e.currentTarget.innerText});
  }

  renderThemeListGroup(){
    return(
      <ListGroup as="ul">
        {Object.keys(themes).map((e) => <ListGroup.Item action as="li" active={this.state.selectedThemeInList === e} onClick={this.onListGroupItemClicked} key={e}>{e}</ListGroup.Item>)}
      </ListGroup>);
  }

  renderModals(){
      return (<div>
        <Modal
          size="sm"
          show={this.state.showThemeModal}
          onHide={this.hideThemeModal}
          aria-labelledby="example-modal-sizes-title-sm"
          className={'Calculator-show-theme-modal'}
          id={'themeModal'}
          centered
          >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
              Choose Theme
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
              {this.renderThemeListGroup()}
          </Modal.Body>
              <Button variant="primary" className={'Calculator-show-theme-modal-apply-button'} onClick={this.onApplyThemeButtonClicked}>
                Apply
              </Button>
        </Modal>
      </div>);
  }
  render() {
    return (
        <div className="Calculator">
          {this.renderModals()}
          {this.renderDisplay()}
          <Keyboard onKeyboardButtonPress={this.onKeyboardButtonPress} onKeyboardButtonHold={this.onKeyboardButtonHold} focusedElementType={this.state.focusedElementType} onListGroupItemButtonPress={this.onListGroupItemButtonPress} installPromptEvent={this.state.installPromptEvent} isUsingMobileVersion={this.isUsingMobileVersion()}/>
        </div>
    );
  }
}

export default Calculator;

import React, { Component } from 'react';
import './KeyboardButton.css'

class KeyboardButton extends Component {

    constructor(props){
        super(props);

        this.handleOnClick = this.handleOnClick.bind(this);
        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleMouseUp = this.handleMouseUp.bind(this);
        this.handleMouseHold = this.handleMouseHold.bind(this);
        this.handleTouchMove = this.handleTouchMove.bind(this);

        this.state = {
            holdCount: 0
        }
    }

    handleOnClick(e){
        e.preventDefault();
        this.props.onClick(this.props.valueType, this.props.value);
        this.setState({mouseHold: false});
    }

    handleMouseHold(holdCount){
        if(this.props.onHold && this.state.mouseHold && 
            ((this.state.holdCount === holdCount) || (this.state.holdCount === holdCount + 1))){
            this.props.onHold(this.props.valueType, this.props.value);
            setTimeout(this.handleMouseHold, 50, this.state.holdCount + 1);
            this.setState({holdCount: this.state.holdCount + 1});
        }
    }

    handleMouseDown(e){
        if(e.type === 'mousedown'){
            e.preventDefault();
        }
        setTimeout(this.handleMouseHold, 300, this.state.holdCount);
        this.setState({mouseHold: true, holdCount: this.state.holdCount + 1});
    }

    handleMouseUp(e){
        this.setState({mouseHold: false});
    }
    
    handleTouchMove(e){
        let r = Math.floor(Math.random() * 255);
        let g = Math.floor(Math.random() * 255);
        let b = Math.floor(Math.random() * 255);
        e.target.style.backgroundColor = `rgb(${r}, ${g}, ${b})`;
    }

    render(){
        const style = {
            gridArea: `${this.props.valueType}_${this.props.value}`,
            userSelect: 'none'
        };

        return(
            <div className={this.props.className} style={style} 
            onMouseDown={e => this.handleMouseDown(e)} onMouseUp={e => this.handleMouseUp(e)}
            onTouchStart={e => this.handleMouseDown(e)} onTouchEnd={ e=> this.handleMouseUp(e)}
            onClick={e => this.handleOnClick(e)}>
                <div className={`KeyboardButton-inner`}>
                    {this.props.children}
                </div>
            </div>
        );
    }

}

export default KeyboardButton;
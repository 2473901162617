import React, { Component } from 'react';
import KeyboardButton from '../KeyboardButton/KeyboardButton'
import './Keyboard.css';
import MaterialIcon from 'material-icons-react';
import {OverlayTrigger, Button, Popover, ListGroup}  from 'react-bootstrap';
import {ADD_TO_HOME_SCREEN, CHANGE_THEME, BUY_DEVELOPER_COFFEE, MORE_APPS, PRIVACY_POLICY, TERMS_OF_USE} from '../../strings-en';

class Keyboard extends Component {

    constructor(props){
        super(props);
        this.onSettingsEnter = this.onSettingsEnter.bind(this);
        this.onSettingsExit = this.onSettingsExit.bind(this);

        this.state = {
            settingsShowing: false
        }
    }

    onSettingsEnter(){
        this.setState({settingsShowing: true});
    }

    onSettingsExit(){
        this.setState({settingsShowing: false});
    }
    
    render(){
        const style = {
            gridTemplateAreas: "'number_7 number_8 number_9 action_backspace' 'number_4 number_5 number_6 action_previous' 'number_1 number_2 number_3 action_next' 'action_clear number_0 action_settings action_next'"
        };
        const display = {display: 'block'};
        const displayNone = {display: 'none'};
        const placement = 'top';
        let nextButtonIcon = <div className={'Keyboard-next-button-icon'}>
            <div style={this.props.focusedElementType === "minute" ? display : displayNone}>
                +
            </div>
            <div style={this.props.focusedElementType === "minute" ? displayNone : display}>
                :
            </div>
        </div>
        let addToHomeScreen = this.props.installPromptEvent ? <ListGroup.Item onClick={this.props.onListGroupItemButtonPress}>{ADD_TO_HOME_SCREEN}</ListGroup.Item> : null;
        let privacyPolicyButton = this.props.isUsingMobileVersion ? <ListGroup.Item onClick={this.props.onListGroupItemButtonPress}>{PRIVACY_POLICY}</ListGroup.Item> : null;
        let termsOfUseButton = this.props.isUsingMobileVersion ? <ListGroup.Item onClick={this.props.onListGroupItemButtonPress}>{TERMS_OF_USE}</ListGroup.Item> : null;
        return(
            <div className="Keyboard" style={style}>
                    <KeyboardButton valueType="number" value="7" onClick={this.props.onKeyboardButtonPress} className={`KeyboardButton-0 KeyboardButton-outline-l`}>7</KeyboardButton>
                    <KeyboardButton valueType="number" value="8" onClick={this.props.onKeyboardButtonPress} className={`KeyboardButton-0`}>8</KeyboardButton>
                    <KeyboardButton valueType="number" value="9" onClick={this.props.onKeyboardButtonPress} className={`KeyboardButton-0`}>9</KeyboardButton>
                    <KeyboardButton valueType="action" value="backspace" onClick={this.props.onKeyboardButtonPress} onHold={this.props.onKeyboardButtonHold} className={`KeyboardButton-1`}><MaterialIcon icon="backspace"/></KeyboardButton>
                    <KeyboardButton valueType="number" value="4" onClick={this.props.onKeyboardButtonPress} className={`KeyboardButton-0 KeyboardButton-outline-l`}>4</KeyboardButton>
                    <KeyboardButton valueType="number" value="5" onClick={this.props.onKeyboardButtonPress} className={`KeyboardButton-0`}>5</KeyboardButton>
                    <KeyboardButton valueType="number" value="6" onClick={this.props.onKeyboardButtonPress} className={`KeyboardButton-0`}>6</KeyboardButton>
                    <KeyboardButton valueType="action" value="previous" onClick={this.props.onKeyboardButtonPress} onHold={this.props.onKeyboardButtonHold} className={`KeyboardButton-1`}><MaterialIcon icon="arrow_back"/></KeyboardButton>
                    <KeyboardButton valueType="number" value="1" onClick={this.props.onKeyboardButtonPress} className={`KeyboardButton-0 KeyboardButton-outline-l`}>1</KeyboardButton>
                    <KeyboardButton valueType="number" value="2" onClick={this.props.onKeyboardButtonPress} className={`KeyboardButton-0`}>2</KeyboardButton>
                    <KeyboardButton valueType="number" value="3" onClick={this.props.onKeyboardButtonPress} className={`KeyboardButton-0`}>3</KeyboardButton>
                    <KeyboardButton valueType="action" value="next" onClick={this.props.onKeyboardButtonPress} onHold={this.props.onKeyboardButtonHold} className={`KeyboardButton-1 KeyboardButton-outline-b`}>{nextButtonIcon}</KeyboardButton>
                    <KeyboardButton valueType="action" value="clear" onClick={this.props.onKeyboardButtonPress} className={`KeyboardButton-1 KeyboardButton-outline-l KeyboardButton-outline-b`}><MaterialIcon icon="refresh"/></KeyboardButton>
                    <KeyboardButton valueType="number" value="0" onClick={this.props.onKeyboardButtonPress} className={`KeyboardButton-0 KeyboardButton-outline-b`}>0</KeyboardButton>
                    <KeyboardButton valueType="action" value="settings" onClick={this.props.onKeyboardButtonPress} className={`KeyboardButton-1 KeyboardButton-outline-b ${this.state.settingsShowing ? 'KeyboardButton-1-active' : ''}`}>
                    <OverlayTrigger trigger="click" key={placement} placement={placement} 
                        onEnter={this.onSettingsEnter} onExit={this.onSettingsExit}
                        rootClose={true}
                        overlay={
                        <Popover className="Keyboard-settings-popover">
                            <ListGroup>
                                {addToHomeScreen}
                                <ListGroup.Item onClick={this.props.onListGroupItemButtonPress}>{CHANGE_THEME}</ListGroup.Item>
                                <ListGroup.Item onClick={this.props.onListGroupItemButtonPress}>{BUY_DEVELOPER_COFFEE}</ListGroup.Item>
                                <ListGroup.Item onClick={this.props.onListGroupItemButtonPress}>{MORE_APPS}</ListGroup.Item>
                                { privacyPolicyButton }
                                { termsOfUseButton }
                            </ListGroup>
                        </Popover>
                        }>
                        <Button variant="secondary" className="Keyboard-settings-button">
                            <MaterialIcon icon="settings"/>
                        </Button>
                    </OverlayTrigger>
                    </KeyboardButton>
            </div>
        );
    }
}

export default Keyboard;